import React, { useState } from "react";
import axios from "axios";
import dayjs from "dayjs";

import Modal from "../../../common/Modal";
import { locale_opt_8 } from "../../../common/Commons";
import InputAssignAssetCoin from "./InputAssignAssetCoin"; 
import InputAssignAssetFiat from "./InputAssignAssetFiat"; 


function CurrentBalance({ exchangesSelect, balanceStatus,source }) {
    // Modal 1 - DATA
    //console.log("[디버깅] source : "+ source)
    const [modalOpen, setModalOpen] = useState(false);
    const [times, setTimes] = useState("Loading...");

    // Modal 1
    const openModal = () => {
        setInterval(function () {
            setTimes(dayjs().format("hh:mm:ss"));
        }, 1000);

        setModalOpen(true);
        const info_total = { bot_id: "p_bot", msg: "refresh_balance", event_type: "dash_board", order_type: "", ticker_name: "", event_amount: "", transaction_date: dayjs().format("YYYY-MM-DD hh:mm:ss") };
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, info_total)
            .catch((err) => {
                alert("ERROR");
                //console.log(err);
            })
            .finally(() => {
                window.location.reload();
            });
    };

    // Modal 2 - DATA
    const [modalOpen2, setModalOpen2] = useState(false);
    const [modalData, setModalData] = useState({});
    const [modalInputValue, setModalInputValue] = useState(1);

    // Modal 2
    const openModal2 = (name) => {
        exchangesSelect === "1" ? (name.exchanges_str = "BINANCE") : exchangesSelect === "2" ? (name.exchanges_str = "UPBIT") : exchangesSelect === "3" ? (name.exchanges_str = "BITHUMB") : exchangesSelect === "4" ? (name.exchanges_str = "OKEX") : exchangesSelect === "5" ? (name.exchanges_str = "MEXC") : (name.exchanges_str = "BITGET");
        name.exchanges = exchangesSelect;
        name.grp === "MLC" ? (name.strategy_grp = "1") : name.grp === "P2V" ? (name.strategy_grp = "2") : (name.strategy_grp = "3");
        setModalOpen2(true);
        setModalData(name);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalOpen2(false);
    };

    function ChangeBotCount() {

        var source_type = "pyramiding"
        if (source == "p_bot_optimize"){
            source_type = "optimize"
        }

        const info_total = { bot_id: "p_bot", msg: "update_bot_count", modalData: modalData, bot_cnt: modalInputValue ,source_type : source_type};
        //console.log("ChangeBotCount , info_total : " + JSON.stringify(info_total));
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, info_total)
            .then((res) => {
                if (res.data.result == "ok") {
                    alert(res.data.msg);
                    window.location.reload();
                }else {
                    alert("Error!\n\nThere is still a bot opened !");
                }
            })
            .catch((err) => {
                //console.log(err);
            });
    }

     // Modal for shot order 
    const [modalOpen2_short_order, setModalOpen2_short_order] = useState(false);
    const [modalData_short_order, setModalData_short_order] = useState({});

    const [modalOpen2_long_order, setModalOpen2_long_order] = useState(false);
    const [modalData_long_order, setModalData_long_order] = useState({});

    const [modalInputValue_short_order, setModalInputValue_short_order] = useState(1);
    const [modalInputValue_long_order, setModalInputValue_long_order] = useState(1);
    

    // Modal for shot order 
    const openModal_short_order = (name) => {
    exchangesSelect === "1" ? (name.exchanges_str = "BINANCE") : exchangesSelect === "2" ? (name.exchanges_str = "UPBIT") : exchangesSelect === "3" ? (name.exchanges_str = "BITHUMB") : exchangesSelect === "4" ? (name.exchanges_str = "OKEX") : exchangesSelect === "5" ? (name.exchanges_str = "MEXC") : (name.exchanges_str = "BITGET");
    name.exchanges = exchangesSelect;
    name.grp === "MLC" ? (name.strategy_grp = "1") : name.grp === "P2V" ? (name.strategy_grp = "2") : (name.strategy_grp = "3");
    setModalOpen2_short_order(true);
    setModalData_short_order(name);
    };



    const closeModal_short_orde = () => {
        setModalData_short_order(false);
        setModalOpen2_short_order(false);
    };

    // Modal for long order 
    const openModal_long_order = (name) => {
        exchangesSelect === "1" ? (name.exchanges_str = "BINANCE") : exchangesSelect === "2" ? (name.exchanges_str = "UPBIT") : exchangesSelect === "3" ? (name.exchanges_str = "BITHUMB") : exchangesSelect === "4" ? (name.exchanges_str = "OKEX") : exchangesSelect === "5" ? (name.exchanges_str = "MEXC") : (name.exchanges_str = "BITGET");
        name.exchanges = exchangesSelect;
        name.grp === "MLC" ? (name.strategy_grp = "1") : name.grp === "P2V" ? (name.strategy_grp = "2") : (name.strategy_grp = "3");
        setModalOpen2_long_order(true);
        setModalData_long_order(name);
        };

    const closeModal_long_orde = () => {
        setModalData_long_order(false);
        setModalOpen2_long_order(false);
    };

    function ChangeContents_short_order() {
        const info_total = { bot_id: "p_bot", msg: "update_bot_short_order_count", modalData_short_order: modalData_short_order, bot_short_order_cnt: modalInputValue_short_order };

        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, info_total)
            .then((res) => {
                if (res.data.result == "ok") {
                    alert(res.data.msg);
                    window.location.reload();
                }else {
                    alert("Error!\n\nThere is still a bot opened !");
                }
            })
            .catch((err) => {
                //console.log(err);
            });
    }

    function ChangeContents_long_order() {
        const info_total = { bot_id: "p_bot", msg: "update_bot_long_order_count", modalData_long_order: modalData_long_order, bot_long_order_cnt: modalInputValue_long_order };

        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, info_total)
            .then((res) => {
                if (res.data.result == "ok") {
                    alert(res.data.msg);
                    window.location.reload();
                }else {
                    alert("Error!\n\nThere is still a bot opened !");
                }
            })
            .catch((err) => {
                //console.log(err);
            });
    }
    

    const [wallet , setWallet] = useState('');

    const [openAssignUiCoin, setopenAssignUiCoin] = useState(false);
    const openUiOfAssignCoin = (tempWallet) => {
        setWallet(tempWallet);
        setopenAssignUiCoin(true);
    };
    const closeUiOfAssignCoin = () => {
        setopenAssignUiCoin(false);
    };

    const [openAssignUiFiat, setopenAssignUiFiat] = useState(false);
    const openUiOfAssignFiat = (tempWallet) => {
        setWallet(tempWallet);
        setopenAssignUiFiat(true);
    };
    const closeUiOfAssignFiat = () => {
        setopenAssignUiFiat(false);
    };




    return (
        <div className="first_div">
            <div className="second_div">
                <div style={{ display: "flex", margin: "0px 0px 1em" }}>
                    <h5 style={{ margin: "0px 2.5em 0px 0px" }}>Current Balance Status</h5>
                    <button className="btn btn-success btn-sm" onClick={(e) => openModal()}>
                        Refresh Balance
                    </button>
                    &emsp;
                    <h6>(updated : {dayjs().format("YYYY-MM-DD dddd hh:mm:ss")})</h6>
                </div>
                <div style={{ display: "flex", marginLeft: "-1em" }}>
                    &emsp;&emsp;
                    <div style={{ width: "31%" }}>
                        <h5>MLC</h5>
                        <table>
                            <thead style={{ background: "#6CA0EC" }}>
                                <tr>
                                    <th>Crypto (price)​</th>
                                    {source =="p_bot_optimize" ? (
                                                        <>
                                                            <th>Bot Count</th>
                                                        </>
                                                    ) : (
                                                        <th>Bot Count</th>
                                                    )
                                    }

                                   
                                    {/*<th>Short Order</th>
                                    <th>Long Order</th>*/}
                                    <th>Coin Assignment&nbsp;(%)</th>
                                    <th>Fiat Assignment&nbsp;(%)</th>
                                    <th>Free​</th>
                                    <th>Total​ (value)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {balanceStatus &&
                                    balanceStatus.map((name, i) =>
                                        name.grp === "MLC" ? (
                                            <tr key={i} style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                                <td>
                                                    {name.crypto}
                                                    <span style={{ color: "#FFC000" }}> ({"c_price" in name ? parseFloat(name.c_price).toLocaleString("ko-KR", locale_opt_8) : 0})</span>
                                                </td>

                                                <td>

                                                
                                                    {source =="p_bot_optimize" ? (
                                                                        <>
                                                                            {"strategy_count_optimize" in name ? (
                                                                                <>
                                                                                    {name.strategy_count_optimize} <button onClick={(e) => openModal2(name)}> update </button>
                                                                                </>
                                                                            ) : (
                                                                                "X"
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {"strategy_count" in name ? (
                                                                                <>
                                                                                    {name.strategy_count} <button onClick={(e) => openModal2(name)}> update </button>
                                                                                </>
                                                                            ) : (
                                                                                "X"
                                                                            )}
                                                                        </>
                                                                    )
                                                    }

                                                  
                                                </td>
                                                {/*
                                                <td>
                                                    {"startegy_short_order_count" in name ? (
                                                        <>
                                                            {name.startegy_short_order_count} <button onClick={(e) => openModal_short_order(name)}> update </button>
                                                        </>
                                                    ) : (
                                                        "X"
                                                    )}
                                                </td>
                                                 <td>
                                                    {"startegy_long_order_count" in name ? (
                                                        <>
                                                            {name.startegy_long_order_count} <button onClick={(e) => openModal_long_order(name)}> update </button>
                                                        </>
                                                    ) : (
                                                        "X"
                                                    )}
                                                </td>
                                                */}
                                                {name.crypto !=="KRW" ? (
                                                        <>
                                                        <td>{name.crypto_assignment == "" ? 0 :  name.crypto_assignment}</td>
                                                         </>
                                                    ):
                                                    (
                                                        <td>X</td>
                                                    )
                                                 }


                                                {name.crypto !=="KRW" ? (
                                                        <>
                                                        <td>{name.fiat_assignment == "" ? 0 :  name.fiat_assignment}</td>
                                                        </>
                                                    ):
                                                    (
                                                        <td>X</td>
                                                    )
                                                 }
                                                
                                                <td>{parseFloat(name.free).toLocaleString("ko-KR", locale_opt_8)}</td>
                                                <td>
                                                    {parseFloat(name.total).toLocaleString("ko-KR", locale_opt_8)}
                                                    {name.crypto !== "USDT" || name.crypto !== "KRW" ? "c_price" in name ? name.total !== 0 ? <span style={{ color: "#FFC000" }}> ({(parseFloat(name.total) * parseFloat(name.c_price)).toLocaleString("ko-KR", locale_opt_8)})</span> : null : null : null}
                                                </td>
                                            </tr>
                                        ) : null
                                    )}
                            </tbody>
                        </table>
                        <button className="btn btn-success btn-sm" style={{color: "black" , backgroundColor : "#dca00b" , margin: "10px 0px 0px 0px"}} onClick={(e) => openUiOfAssignCoin("mlc")}>
                            Edit Coin Assignment
                        </button>
                        <button className="btn btn-success btn-sm" style={{color: "black" , backgroundColor : "#dca00b" , margin: "10px 0px 0px 10px"}} onClick={(e) => openUiOfAssignFiat("mlc")}>
                            Edit Fiat Assignment
                        </button>
                    </div>

                    &emsp;&emsp;
                    <div style={{ width: "31%" }}>
                        <h5>P2V</h5>
                        <table>
                            <thead style={{ background: "#6CA0EC" }}>
                                <tr>
                                    <th>Crypto (price)​</th>
                                    <th>Bot Count</th>
                                    {/* 
                                    <th>Short Order</th>
                                    <th>Long Order</th>*/}
                                    <th>Coin Assignment&nbsp;(%)</th>
                                    <th>Fiat Assignment&nbsp;(%)</th>
                                    <th>Free​</th>
                                    <th>Total​ (value)</th>
                                </tr>
                            </thead>
                            <tbody>
                               
                                {exchangesSelect!=4 && balanceStatus &&
                                    balanceStatus.map((name, i) =>
                                        name.grp === "P2V" ? (
                                            <tr key={i} style={{ background: i%2 === 0 ? "#114B39" : "#0B2F24​"}}>
                                                <td>
                                                    {name.crypto}
                                                    <span style={{ color: "#FFC000" }}> ({"c_price" in name ? parseFloat(name.c_price).toLocaleString("ko-KR", locale_opt_8) : 0})</span>
                                                </td>
                                                <td>
                                                    {source =="p_bot_optimize" ? (
                                                                            <>
                                                                                {"strategy_count_optimize" in name ? (
                                                                                    <>
                                                                                        {name.strategy_count_optimize} <button onClick={(e) => openModal2(name)}> update </button>
                                                                                    </>
                                                                                ) : (
                                                                                    "X"
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {"strategy_count" in name ? (
                                                                                    <>
                                                                                        {name.strategy_count} <button onClick={(e) => openModal2(name)}> update </button>
                                                                                    </>
                                                                                ) : (
                                                                                    "X"
                                                                                )}
                                                                            </>
                                                                        )
                                                        }
                                                </td>
                                                {/* 
                                                <td>
                                                    {"startegy_short_order_count" in name ? (
                                                        <>
                                                            {name.startegy_short_order_count} <button onClick={(e) => openModal_short_order(name)}> update </button>
                                                        </>
                                                    ) : (
                                                        "X"
                                                    )}
                                                </td>
                                                 <td>
                                                    {"startegy_long_order_count" in name ? (
                                                        <>
                                                            {name.startegy_long_order_count} <button onClick={(e) => openModal_long_order(name)}> update </button>
                                                        </>
                                                    ) : (
                                                        "X"
                                                    )}
                                                </td>
                                                */}
                                                {name.crypto !=="KRW" ? (
                                                        <>
                                                        <td>{name.crypto_assignment == "" ? 0 :  name.crypto_assignment}</td>
                                                         </>
                                                    ):
                                                    (
                                                        <td>X</td>
                                                    )
                                                 }


                                                {name.crypto !=="KRW" ? (
                                                        <>
                                                        <td>{name.fiat_assignment == "" ? 0 :  name.fiat_assignment}</td>
                                                        </>
                                                    ):
                                                    (
                                                        <td>X</td>
                                                    )
                                                 }
                                                <td>{parseFloat(name.free).toLocaleString("ko-KR", locale_opt_8)}</td>
                                                <td>
                                                    {parseFloat(name.total).toLocaleString("ko-KR", locale_opt_8)}
                                                    {name.crypto !== "USDT" || name.crypto !== "KRW" ? "c_price" in name ? name.total !== 0 ? <span style={{ color: "#FFC000" }}> ({(parseFloat(name.total) * parseFloat(name.c_price)).toLocaleString("ko-KR", locale_opt_8)})</span> : null : null : null}
                                                </td>
                                            </tr>
                                        ) : null
                                    )}
                            </tbody>
                        </table>
                        <button className="btn btn-success btn-sm" style={{color: "black" , backgroundColor : "#dca00b" , margin: "10px 0px 0px 0px"}} onClick={(e) => openUiOfAssignCoin("p2v")}>
                            Edit Coin Assignment
                        </button>
                        <button className="btn btn-success btn-sm" style={{color: "black" , backgroundColor : "#dca00b" , margin: "10px 0px 0px 10px"}} onClick={(e) => openUiOfAssignFiat("p2v")}>
                            Edit Fiat Assignment
                        </button>
                    </div>
                    &emsp;&emsp;
                    <div style={{ width: "31%" }}>
                        <h5>TBT</h5>
                        <table>
                            <thead style={{ background: "#6CA0EC" }}>
                                <tr>
                                    <th>Crypto (price)​</th>
                                    <th>Bot Count</th>
                                    {/*<th>Short Order</th>
                                    <th>Long Order</th>*/}
                                    <th>Coin Assignment&nbsp;(%)</th>
                                    <th>Fiat Assignment&nbsp;(%)</th>
                                    <th>Free​</th>
                                    <th>Total​ (value)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {balanceStatus &&
                                    balanceStatus.map((name, i) =>
                                        name.grp === "TBT" ? (
                                            <tr key={i} style={{ background: i%2 === 0 ? "#271137​" :"#3C1B55" }}>
                                                <td>
                                                    {name.crypto}
                                                    <span style={{ color: "#FFC000" }}> ({"c_price" in name ? parseFloat(name.c_price).toLocaleString("ko-KR", locale_opt_8) : 0})</span>
                                                </td>
                                                <td>
                                                {source =="p_bot_optimize" ? (
                                                                        <>
                                                                            {"strategy_count_optimize" in name ? (
                                                                                <>
                                                                                    {name.strategy_count_optimize} <button onClick={(e) => openModal2(name)}> update </button>
                                                                                </>
                                                                            ) : (
                                                                                "X"
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {"strategy_count" in name ? (
                                                                                <>
                                                                                    {name.strategy_count} <button onClick={(e) => openModal2(name)}> update </button>
                                                                                </>
                                                                            ) : (
                                                                                "X"
                                                                            )}
                                                                        </>
                                                                    )
                                                    }
                                                </td>
                                                {/* 
                                                <td>
                                                    {"startegy_short_order_count" in name ? (
                                                        <>
                                                            {name.startegy_short_order_count} <button onClick={(e) => openModal_short_order(name)}> update </button>
                                                        </>
                                                    ) : (
                                                        "X"
                                                    )}
                                                </td>
                                                 <td>
                                                    {"startegy_long_order_count" in name ? (
                                                        <>
                                                            {name.startegy_long_order_count} <button onClick={(e) => openModal_long_order(name)}> update </button>
                                                        </>
                                                    ) : (
                                                        "X"
                                                    )}
                                                </td>
                                                */}

                                                {name.crypto !=="KRW" ? (
                                                        <>
                                                        <td>{name.crypto_assignment == "" ? 0 :  name.crypto_assignment}</td>
                                                         </>
                                                    ):
                                                    (
                                                        <td>X</td>
                                                    )
                                                 }


                                                {name.crypto !=="KRW" ? (
                                                        <>
                                                        <td>{name.fiat_assignment == "" ? 0 :  name.fiat_assignment}</td>
                                                        </>
                                                    ):
                                                    (
                                                        <td>X</td>
                                                    )
                                                 }
                                                <td>{parseFloat(name.free).toLocaleString("ko-KR", locale_opt_8)}</td>
                                                <td>
                                                    {parseFloat(name.total).toLocaleString("ko-KR", locale_opt_8)}
                                                    {name.crypto !== "USDT" || name.crypto !== "KRW" ? "c_price" in name ? name.total !== 0 ? <span style={{ color: "#FFC000" }}> ({(parseFloat(name.total) * parseFloat(name.c_price)).toLocaleString("ko-KR", locale_opt_8)})</span> : null : null : null}
                                                </td>
                                            </tr>
                                        ) : null
                                    )}
                            </tbody>
                        </table>
                        <button className="btn btn-success btn-sm" style={{color: "black" , backgroundColor : "#dca00b" , margin: "10px 0px 0px 0px"}} onClick={(e) => openUiOfAssignCoin("tbt")}>
                            Edit Coin Assignment
                        </button>
                        <button className="btn btn-success btn-sm" style={{color: "black" , backgroundColor : "#dca00b" , margin: "10px 0px 0px 10px"}} onClick={(e) => openUiOfAssignFiat("tbt")}>
                            Edit Fiat Assignment
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <Modal open={modalOpen} close={closeModal} header="Balance Statues" footer={<></>}>
                    {/* Modal.js <main> {props.children} </main>에 내용이 입력된다.  */}
                    <h3>Balance Updating...</h3>
                    <br />
                    <h4>Local Time is : {times}</h4>
                </Modal>
            </div>
            <br />
            <div>
                <Modal
                    open={modalOpen2}
                    close={closeModal}
                    header={
                        <>
                            <p>
                                {modalData.exchanges_str}_{modalData.grp}_{modalData.crypto}
                            </p>
                            <button className="close" onClick={closeModal}>
                                {" "}
                                &times;{" "}
                            </button>
                        </>
                    }
                    footer={
                        modalInputValue <= 0 || modalInputValue >= 16 ? (
                            <span style={{ fontSize: "11px", color: "red" }}>1 이상 15 이하의 값을 넣어 주세요 &emsp;</span>
                        ) : (
                            <>
                                <button style={{ background: "green" }} onClick={(e) => ChangeBotCount()}>
                                    Save
                                </button>
                                <button className="close" onClick={closeModal}>
                                    {" "}
                                    close{" "}
                                </button>
                            </>
                        )
                    }>
                    {/* Modal.js <main> {props.children} </main>에 내용이 입력된다.  */}
                    <label htmlFor="bot_modal_input">Strategy Count: </label>
                    <input type="number" id="bot_modal_input" name="bot_modal_input" autoFocus defaultValue={source =="p_bot_optimize" ? modalData.strategy_count_optimize :  modalData.strategy_count } onChange={(e) => setModalInputValue(e.target.value)} />
                </Modal>
            </div>
            <br />
            <div>
                <Modal
                    open={modalOpen2_short_order}
                    close={closeModal_short_orde}
                    header={
                        <>
                            <p>
                                {modalData_short_order.exchanges_str}_{modalData_short_order.grp}_{modalData_short_order.crypto}
                            </p>
                            <button className="close" onClick={closeModal_short_orde}>
                                {" "}
                                &times;{" "}
                            </button>
                        </>
                    }
                    footer={
                        modalInputValue_short_order <= 0 || modalInputValue_short_order >= 16 ? (
                            <span style={{ fontSize: "11px", color: "red" }}>1 이상 10 이하의 값을 넣어 주세요 &emsp;</span>
                        ) : (
                            <>
                                <button style={{ background: "green" }} onClick={(e) => ChangeContents_short_order()}>
                                    Save
                                </button>
                                <button className="close" onClick={closeModal_short_orde}>
                                    {" "}
                                    close{" "}
                                </button>
                            </>
                        )
                    }>
                    {/* Modal.js <main> {props.children} </main>에 내용이 입력된다.  */}
                    <label htmlFor="bot_short_order_modal_input">Short Bot Count: </label>
                    <input type="number" id="bot_short_order_modal_input" name="bot_short_order_modal_input" autoFocus defaultValue={modalData_short_order.startegy_short_order_count} onChange={(e) => setModalInputValue_short_order(e.target.value)} />
                </Modal>
            </div>
            <div>
                <Modal
                    open={modalOpen2_long_order}
                    close={closeModal_long_orde}
                    header={
                        <>
                            <p>
                                {modalData_long_order.exchanges_str}_{modalData_long_order.grp}_{modalData_long_order.crypto}
                            </p>
                            <button className="close" onClick={closeModal_long_orde}>
                                {" "}
                                &times;{" "}
                            </button>
                        </>
                    }
                    footer={
                        modalInputValue_short_order <= 0 || modalInputValue_short_order >= 16 ? (
                            <span style={{ fontSize: "11px", color: "red" }}>1 이상 10 이하의 값을 넣어 주세요 &emsp;</span>
                        ) : (
                            <>
                                <button style={{ background: "green" }} onClick={(e) => ChangeContents_long_order()}>
                                    Save
                                </button>
                                <button className="close" onClick={closeModal_long_orde}>
                                    {" "}
                                    close{" "}
                                </button>
                            </>
                        )
                    }>
                    {/* Modal.js <main> {props.children} </main>에 내용이 입력된다.  */}
                    <label htmlFor="bot_long_order_modal_input">Long Bot Count: </label>
                    <input type="number" id="bot_long_order_modal_input" name="bot_long_order_modal_input" autoFocus defaultValue={modalData_long_order.startegy_long_order_count} onChange={(e) => setModalInputValue_long_order(e.target.value)} />
                </Modal>
            </div>

            
            { openAssignUiCoin && (
                <div style={{ justifyContent : "center",margin: "0px 0px 0px 0px" }}>
                    <InputAssignAssetCoin exchange_no={exchangesSelect} wallet={wallet} balanceStatus={balanceStatus} closeUiOfAssignCoin={closeUiOfAssignCoin}/>
                </div>
                )}

            { openAssignUiFiat && (
                <div style={{justifyContent : "center", margin: "0px 0px 0px 0px" }}>
                    <InputAssignAssetFiat exchange_no={exchangesSelect} wallet={wallet} balanceStatus={balanceStatus} closeUiOfAssignFiat={closeUiOfAssignFiat}/>
                </div>
                )}
            
        </div>
    );
}

export default CurrentBalance;
