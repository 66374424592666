import React, { useState, useEffect } from "react";

import axios from "axios";
import dayjs from "dayjs";


function InputAssignAssetCoin({ exchange_no, wallet ,balanceStatus,closeUiOfAssignCoin }) {

      
console.log("wallet : " + wallet)
// 버튼 스타일
const btnStyle = {
    margin: "0 5px",
    padding: "10px",
    cursor: "pointer",
    border: "1px solid #ccc",
    borderRadius: "3px",
    backgroundColor: "#f8f8f8"
};

  
  const [coinList, setCoinList] = useState([]);
  const [assignValues_before, setAssignValues_before] = useState({});
  const [assignValues, setAssignValues] = useState({});
  const [assignPercent, setAssignPercent] = useState({});

  function closeAsset(){
    closeUiOfAssignCoin();
  }

  function updateAsset() {

    const info_total = { bot_id: "f_bot", 
                        msg: "update_coin_assignment",
                        exchange_no : exchange_no, 
                        wallet : wallet, 
                        coinList: coinList, 
                        assignValues: assignValues ,
                        assignPercent : assignPercent,
                        assignValues_before : assignValues_before
                      };
 
    axios
        .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, info_total)
        .then((res) => {
            if (res.data.result == "ok") {
                alert(res.data.msg);
                closeUiOfAssignCoin();
            }else {
                alert("Error!\n\nThere is a system error !");
                closeUiOfAssignCoin();
            }
        })
        .catch((err) => {
            //console.log(err);
        });
    
  }


  function calAssignValue(assing_ratio, current_qty, target_form_name, percent_form_name) {
    if (assing_ratio !== "" && current_qty !== "") {
      let temp_assing_ratio = parseFloat(assing_ratio.replace(",", "."));
      let temp_current_qty = parseFloat(current_qty);


      let temp_update_qty = temp_current_qty * (temp_assing_ratio / 100);

      setAssignValues((prevValues) => ({
        ...prevValues,
        [target_form_name]: temp_update_qty.toFixed(8),
      }));
    }

    setAssignPercent((prevValues) => ({
        ...prevValues,
        [percent_form_name]: assing_ratio,
      }));

  }

  function addValue(percent_form_name, current_qty, target_form_name) {

    console.log("percent_form_name : " + percent_form_name)
    console.log("assignPercent[percent_form_name] : " + assignPercent[percent_form_name])

    let temp_percent =  parseFloat(assignPercent[percent_form_name] )+ 0.1
    setAssignPercent((prevValues) => ({
        ...prevValues,
        [percent_form_name]: temp_percent.toFixed(1),
      }));

    

    console.log("current_qty : " + current_qty)
    console.log("temp_percent : " + temp_percent)
    console.log("target_form_name : " + target_form_name)

    let temp_qty =  parseFloat(current_qty) * temp_percent / 100
    console.log("temp_qty : " + temp_qty)

    setAssignValues((prevValues) => ({
        ...prevValues,
        [target_form_name]: temp_qty.toFixed(8),
      }));

  }

  function delValue(percent_form_name, current_qty, target_form_name) {

    console.log("percent_form_name : " + percent_form_name)
    console.log("assignPercent[percent_form_name] : " + assignPercent[percent_form_name])

    let temp_percent =  parseFloat(assignPercent[percent_form_name] ) - 0.1
    setAssignPercent((prevValues) => ({
        ...prevValues,
        [percent_form_name]: temp_percent,
      }));

    

    console.log("current_qty : " + current_qty)
    console.log("temp_percent : " + temp_percent)
    console.log("target_form_name : " + target_form_name)

    let temp_qty =  parseFloat(current_qty) * temp_percent / 100
    console.log("temp_qty : " + temp_qty)

    setAssignValues((prevValues) => ({
        ...prevValues,
        [target_form_name]: temp_qty,
      }));

  }
    
useEffect(() => {

    console.log("balanceStatus : " + JSON.stringify(balanceStatus));

    let temp_assignPercent = {};
    let temp_assignValues = {};
    let temp_assignValues_amount = {};
    let tmpe_coinList = [];

    balanceStatus.forEach((name) => {
        if (name.grp.toString().toUpperCase() === wallet.toString().toUpperCase()) {
            // 안전한 숫자 변환
            const crypto_assignment = parseFloat(name.crypto_assignment) || 0;
            const crypto_assignment_amount = parseFloat(name.crypto_assignment_amount) || 0;
            const free = parseFloat(name.free).toFixed(8) || 0;

            // 퍼센트 값 저장
            temp_assignPercent["crypto_assignment_update_" + name.crypto] = crypto_assignment;
            // 계산된 값 저장 (소수점 첫 번째 자리까지 반올림)
            temp_assignValues["crypto_assignment_" + name.crypto] = (free * (crypto_assignment / 100) * 10 ) / 10;

            // 계산된 값 저장 (소수점 첫 번째 자리까지 반올림)
            temp_assignValues_amount["crypto_assignment_" + name.crypto] = Math.round(crypto_assignment_amount);
            // 퍼센트 값 저장
            tmpe_coinList.push(name.crypto);
        }
    });

    console.log("temp_assignPercent : " + JSON.stringify(temp_assignPercent));
    console.log("temp_assignValues : " + JSON.stringify(temp_assignValues));
    console.log("temp_assignValues_amount : " + JSON.stringify(temp_assignValues_amount));
    console.log("tmpe_coinList : " + JSON.stringify(tmpe_coinList));

    setAssignPercent(temp_assignPercent)

    setAssignValues_before(temp_assignValues_amount)
    setAssignValues(temp_assignValues)
    setCoinList(tmpe_coinList)

}, []);

const formatNumberWithComma = (value) => {
  if (value == null || value === '') return '0';

  // 문자열로 변환 후 콤마 추가
  const str = String(value);
  const parts = str.split('.');
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const decimalPart = parts[1] ? `.${parts[1].slice(0, 8)}` : '';

  return integerPart + decimalPart;
};


  return (
    <div>
      <div style={{ width: "60%" }}>
        <h5>Coin Asset Assignment</h5>
        <table>
          <thead style={{ background: "#6CA0EC" }}>
            <tr>
              <th>Coin Name</th>
              <th>Current Free</th>
              <th>Assignment Amount</th>
              <th>New Assignment (Amount)</th>
              <th>New Assignment (Percent)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {balanceStatus &&
              balanceStatus.map((name, i) =>
              
                name.grp.toString().toUpperCase() === wallet.toString().toUpperCase() ? (
                  <tr key={i} style={{ background: i % 2 === 0 ? "#142440" : "#233E6F" }}>
                    <td style={{ width: "10%" }}>{name.crypto}</td>
                    <td style={{ width: "20%" }}>
                      {parseFloat(name.free).toLocaleString("ko-KR", { maximumFractionDigits: 8 })}
                    </td>

                    {name.crypto !== "KRW" ? (
                      <>
                        <td style={{ width: "20%" }}>
                          {formatNumberWithComma(assignValues_before["crypto_assignment_" + name.crypto])}
                        </td>
                      </>
                    ) : (
                      <td style={{ width: "20%" }}>X</td>
                    )}

                    {name.crypto !== "KRW" ? (
                      <>
                        <td style={{ width: "20%" }}>
                        <input
                            onChange={(e) => {
                              let inputValue = e.target.value;

                              // 숫자와 소수점만 허용
                              inputValue = inputValue.replace(/[^0-9.]/g, '');

                              // 소수점이 여러 개일 경우, 첫 번째만 유지
                              const firstDotIndex = inputValue.indexOf('.');
                              if (firstDotIndex !== -1) {
                                const beforeDot = inputValue.slice(0, firstDotIndex);
                                const afterDot = inputValue.slice(firstDotIndex + 1).replace(/\./g, ''); // 나머지 점 제거
                                inputValue = beforeDot + '.' + afterDot;
                              }

                              const parts = inputValue.split('.');
                              let integerPart = parts[0] || '';
                              let decimalPart = parts[1] ?? '';

                              // 정수부 앞자리 0 제거 (단, "0"은 유지)
                              if (integerPart.length > 1) {
                                integerPart = integerPart.replace(/^0+/, '') || '0';
                              }

                              // 소수점 8자리 제한
                              decimalPart = decimalPart.slice(0, 8);

                              // 정수부 콤마 포맷
                              const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                              // 소수점 포함 여부 확인
                              let formattedValue = decimalPart !== '' || inputValue.endsWith('.') 
                                ? `${formattedInteger}.${decimalPart}`
                                : formattedInteger;

                              calAssignValue(
                                formattedValue,
                                name.free,
                                "crypto_assignment_" + name.crypto,
                                "crypto_assignment_" + name.crypto
                              );
                            }}
                            style={{ width: "100%" }}
                            type="text"
                            id={"crypto_assignment_" + name.crypto}
                            name={"crypto_assignment_" + name.crypto}
                            value={
                              (() => {
                                const raw = assignValues["crypto_assignment_" + name.crypto];
                                if (raw === '') return '';

                                const cleaned = String(raw).replace(/,/g, '');
                                const firstDotIndex = cleaned.indexOf('.');
                                let integerPart = firstDotIndex !== -1 ? cleaned.slice(0, firstDotIndex) : cleaned;
                                let decimalPart = firstDotIndex !== -1 ? cleaned.slice(firstDotIndex + 1) : '';

                                if (integerPart.length > 1) {
                                  integerPart = integerPart.replace(/^0+/, '') || '0';
                                }

                                decimalPart = decimalPart.slice(0, 8);
                                const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                                return decimalPart !== '' || String(raw).endsWith('.')
                                  ? `${formattedInteger}.${decimalPart}`
                                  : formattedInteger;
                              })()
                            }
                          />
                        </td>
                      </>
                    ) : (
                      <td style={{ width: "20%" }}>X</td>
                    )}
                    {name.crypto !== "KRW" ? (
                      <>
                        <td style={{ width: "20%" }}>
                        <input
                            onChange={(e) => {
                              let inputValue = e.target.value;

                              // 숫자와 소수점만 허용
                              inputValue = inputValue.replace(/[^0-9.]/g, '');

                              // 소수점이 여러 개일 경우, 첫 번째만 유지
                              const firstDotIndex = inputValue.indexOf('.');
                              if (firstDotIndex !== -1) {
                                const beforeDot = inputValue.slice(0, firstDotIndex);
                                const afterDot = inputValue.slice(firstDotIndex + 1).replace(/\./g, ''); // 나머지 점 제거
                                inputValue = beforeDot + '.' + afterDot;
                              }

                              const parts = inputValue.split('.');
                              let integerPart = parts[0] || '';
                              let decimalPart = parts[1] ?? '';

                              // 정수부 앞자리 0 제거 (단, "0"은 유지)
                              if (integerPart.length > 1) {
                                integerPart = integerPart.replace(/^0+/, '') || '0';
                              }

                              // 소수점 8자리 제한
                              decimalPart = decimalPart.slice(0, 8);

                              // 정수부 콤마 포맷
                              const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                              // 소수점 포함 여부 확인
                              let formattedValue = decimalPart !== '' || inputValue.endsWith('.') 
                                ? `${formattedInteger}.${decimalPart}`
                                : formattedInteger;

                              calAssignValue(
                                formattedValue,
                                name.free,
                                "crypto_assignment_" + name.crypto,
                                "crypto_assignment_update_" + name.crypto
                              );
                            }}
                            style={{ width: "100%" }}
                            type="text"
                            id={"crypto_assignment_update_" + name.crypto}
                            name={"crypto_assignment_update_" + name.crypto}
                            value={
                              (() => {
                                const raw = assignPercent["crypto_assignment_update_" + name.crypto];
                                if (raw === '') return '';

                                const cleaned = String(raw).replace(/,/g, '');
                                const firstDotIndex = cleaned.indexOf('.');
                                let integerPart = firstDotIndex !== -1 ? cleaned.slice(0, firstDotIndex) : cleaned;
                                let decimalPart = firstDotIndex !== -1 ? cleaned.slice(firstDotIndex + 1) : '';

                                if (integerPart.length > 1) {
                                  integerPart = integerPart.replace(/^0+/, '') || '0';
                                }

                                decimalPart = decimalPart.slice(0, 8);
                                const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                                return decimalPart !== '' || String(raw).endsWith('.')
                                  ? `${formattedInteger}.${decimalPart}`
                                  : formattedInteger;
                              })()
                            }
                          />
                        </td>
                      </>
                    ) : (
                      <td style={{ width: "20%" }}>X</td>
                    )}
                    {name.crypto !== "KRW" ? (
                        <>
                          <td style={{ width: "10%" }}>
                            <button style={btnStyle} onClick={(e) => addValue("crypto_assignment_update_" + name.crypto,name.free,"crypto_assignment_" + name.crypto) }>+</button>

                            <button style={btnStyle} onClick={(e) => delValue("crypto_assignment_update_" + name.crypto,name.free,"crypto_assignment_" + name.crypto) }>-</button>
                          </td>
                        </>
                      ) : (
                        <td style={{ width: "10%" }}>X</td>
                      )}
                  </tr>
                ) : null
              )}
          </tbody>
        </table>
        <button className="btn btn-success btn-sm" style={{backgroundColor : "blue" , margin: "10px 0px 0px 0px" }} onClick={updateAsset}>
          Update
        </button>
        <button className="btn btn-success btn-sm" style={{backgroundColor : "blue" , margin: "10px 0px 0px 10px" }} onClick={closeAsset}>
          Close
        </button>
      </div>
    </div>
  );
}

export default InputAssignAssetCoin;
