import axios from "axios";
import React, { useState, useEffect } from "react";

/// functions
/// functions


export function TradingTypeSelector({ tradingTypeSelect, send_esSet, _type="normal" }) {
    const Change = (_val) => {
        send_esSet(_val);
    };
    const all_radio = () => {
    return (
        <>
            <input type="radio" id={"all"} name={"all"} value="all" checked={tradingTypeSelect === "all"} onChange={() => Change("all")} />
            <label htmlFor={"all"}> All</label>
            &emsp;
        </>
    )};

    return (
        <div className="tradingtype_selection_div">
                <div>
                    Select Exchange: &emsp;
                    {_type === "all" ? all_radio() : null}
                    <input type="radio" id={"pyramiding"} name={"pyramiding"} value="1" checked={tradingTypeSelect === "1"} onChange={() => Change("1")} />
                    <label htmlFor={"pyramiding"}> pyramiding</label>
                    &emsp;&emsp;
                    <input type="radio" id={"pyramiding_stk"} name={"pyramiding_stk"} value="2" checked={tradingTypeSelect === "2"} onChange={() => Change("2")} />
                    <label htmlFor={"pyramiding_stk"}> pyramiding_stk</label>
                    &emsp;&emsp;
                    <input type="radio" id={"future"} name={"future"} value="3" checked={tradingTypeSelect === "3"} onChange={() => Change("3")} />
                    <label htmlFor={"future"}> future</label>
                    &emsp;&emsp;
                    <input type="radio" id={"future_stk"} name={"okex"} value="4" checked={tradingTypeSelect === "4"} onChange={() => Change("4")} />
                    <label htmlFor={"future_stk"}> future_stk</label>
                </div>
        </div>
    );
}

export function ExchangesSelector({ exchangesSelect, send_esSet, session_name, _type="normal" }) {
    const Change = (_val) => {
        send_esSet(_val);
        //alert("Common.js, ExchangesSelector :" + _val);
        sessionStorage.setItem(session_name, _val);
    };
    const all_radio = () => {
    return (
        <>
            <input type="radio" id={session_name + "all"} name={session_name + "all"} value="all" checked={exchangesSelect === "all"} onChange={() => Change("all")} />
            <label htmlFor={session_name + "all"}> All</label>
            &emsp;
        </>
    )};

    return (
        <div className="exchange_selection_div">
                <div>
                    Select Exchange: &emsp;
                    {_type === "all" ? all_radio() : null}
                    <input type="radio" id={session_name + "binance"} name={session_name + "binance"} value="1" checked={exchangesSelect === "1"} onChange={() => Change("1")} />
                    <label htmlFor={session_name + "binance"}>&nbsp;&nbsp;Binance</label>
                    &emsp;&emsp;
                    <input type="radio" id={session_name + "upbit"} name={session_name + "upbit"} value="2" checked={exchangesSelect === "2"} onChange={() => Change("2")} />
                    <label htmlFor={session_name + "upbit"}>&nbsp;&nbsp;Upbit</label>
                    &emsp;&emsp;
                    <input type="radio" id={session_name + "bithumb"} name={session_name + "bithumb"} value="3" checked={exchangesSelect === "3"} onChange={() => Change("3")} />
                    <label htmlFor={session_name + "bithumb"}>&nbsp;&nbsp;Bithumb</label>
                    &emsp;&emsp;
                    <input type="radio" id={session_name + "okex"} name={session_name + "okex"} value="4" checked={exchangesSelect === "4"} onChange={() => Change("4")} />
                    <label htmlFor={session_name + "okex"}>&nbsp;&nbsp;Okex</label>
                    &emsp;&emsp;
                    <input type="radio" id={session_name + "mexc"} name={session_name + "mexc"} value="5" checked={exchangesSelect === "5"} onChange={() => Change("5")} />
                    <label htmlFor={session_name + "mexc"}>&nbsp;&nbsp;Mexc</label>
                    &emsp;&emsp;
                    <input type="radio" id={session_name + "bitget"} name={session_name + "bitget"} value="6" checked={exchangesSelect === "6"} onChange={() => Change("6")} />
                    <label htmlFor={session_name + "bitget"}>&nbsp;&nbsp;Bitget</label>
                </div>
        </div>
    );
}


export function ExchangesSelector_dup({ exchangesSelect, send_esSet, session_name, _type="normal" }) {
    const Change = (_val) => {
        send_esSet(_val);
        //alert("Common.js, ExchangesSelector :" + _val);
        sessionStorage.setItem(session_name, _val);
    };
    const all_radio = () => {
    return (
        <>
            <input type="radio" id={session_name + "all_dup"} name={session_name + "all_dup"} value="all" checked={exchangesSelect === "all"} onChange={() => Change("all")} />
            <label htmlFor={session_name + "all_dup"}> All</label>
            &emsp;
        </>
    )};

    return (
        <div className="exchange_selection_div">
                <div>
                    Select Exchange: &emsp;
                    {_type === "all" ? all_radio() : null}
                    <input type="radio" id={session_name + "binance_dup"} name={session_name + "binance_dup"} value="1" checked={exchangesSelect === "1"} onChange={() => Change("1")} />
                    <label htmlFor={session_name + "binance_dup"}>&nbsp;&nbsp;Binance</label>
                    &emsp;&emsp;
                    <input type="radio" id={session_name + "upbit_dup"} name={session_name + "upbit_dup"} value="2" checked={exchangesSelect === "2"} onChange={() => Change("2")} />
                    <label htmlFor={session_name + "upbit_dup"}>&nbsp;&nbsp;Upbit</label>
                    &emsp;&emsp;
                    <input type="radio" id={session_name + "bithumb_dup"} name={session_name + "bithumb_dup"} value="3" checked={exchangesSelect === "3"} onChange={() => Change("3")} />
                    <label htmlFor={session_name + "bithumb_dup"}>&nbsp;&nbsp;Bithumb</label>
                    &emsp;&emsp;
                    <input type="radio" id={session_name + "okex_dup_dup"} name={session_name + "okex_dup"} value="4" checked={exchangesSelect === "4"} onChange={() => Change("4")} />
                    <label htmlFor={session_name + "okex_dup"}>&nbsp;&nbsp;Okex</label>
                    &emsp;&emsp;
                    <input type="radio" id={session_name + "mexc_dup"} name={session_name + "mexc_dup"} value="5" checked={exchangesSelect === "5"} onChange={() => Change("5")} />
                    <label htmlFor={session_name + "mexc_dup"}>&nbsp;&nbsp;Mexc</label>
                    &emsp;&emsp;
                    <input type="radio" id={session_name + "bitget_dup"} name={session_name + "bitget_dup"} value="6" checked={exchangesSelect === "6"} onChange={() => Change("6")} />
                    <label htmlFor={session_name + "bitget_dup"}>&nbsp;&nbsp;Bitget</label>
                </div>
        </div>
    );
}


export function ExchangesSelector_Future({ exchangesSelect, send_esSet, session_name, _type="normal" }) {
    const Change = (_val) => {
        send_esSet(_val);
        //alert("Common.js, ExchangesSelector :" + _val);
        sessionStorage.setItem(session_name, _val);
    };
    const all_radio = () => {
    return (
        <>
            <input type="radio" id={session_name + "all"} name={session_name + "all"} value="all" checked={exchangesSelect === "all"} onChange={() => Change("all")} />
            <label htmlFor={session_name + "all"}> All</label>
            &emsp;
        </>
    )};

    return (
        <div className="exchange_selection_div">
                <div>
                    Select Exchange: &emsp;
                    {_type === "all" ? all_radio() : null}
                    <input type="radio" id={session_name + "binance"} name={session_name + "binance"} value="1" checked={exchangesSelect === "1"} onChange={() => Change("1")} />
                    <label htmlFor={session_name + "binance"}> Binance</label>
                    &emsp;&emsp;
                    {/*
                    <input type="radio" id={session_name + "upbit"} name={session_name + "upbit"} value="2" checked={exchangesSelect === "2"} onChange={() => Change("2")} />
                    <label htmlFor={session_name + "upbit"}> Upbit</label>
                    &emsp;&emsp;
                    <input type="radio" id={session_name + "bithumb"} name={session_name + "bithumb"} value="3" checked={exchangesSelect === "3"} onChange={() => Change("3")} />
                    <label htmlFor={session_name + "bithumb"}> Bithumb</label>
                    &emsp;&emsp;
                    */}
                    <input type="radio" id={session_name + "okex"} name={session_name + "okex"} value="4" checked={exchangesSelect === "4"} onChange={() => Change("4")} />
                    <label htmlFor={session_name + "okex"}> Okex</label>
                    &emsp;&emsp;
                    {/*
                    <input type="radio" id={session_name + "mexc"} name={session_name + "mexc"} value="5" checked={exchangesSelect === "5"} onChange={() => Change("5")} />
                    <label htmlFor={session_name + "mexc"}> Mexc</label>
                    &emsp;&emsp;
                    */}
                    <input type="radio" id={session_name + "bitget"} name={session_name + "bitget"} value="6" checked={exchangesSelect === "6"} onChange={() => Change("6")} />
                    <label htmlFor={session_name + "bitget"}> Bitget</label>
                </div>
        </div>
    );
}


export const C_coins = (coinsSet) => {
    const python_server_url = process.env.REACT_APP_PYTHON_SERVER;
    const data = {
        msg: "get_coins"
    };

    axios
        .post(`${python_server_url}/common_future`, data)
        .then((res) => {
            let coins_g = [];
            if (res.data.result.length !== 0) {
                res.data.result.forEach((element) => {
                    coins_g.push({ value: element.coin_ticker, name: element.coin_ticker.toUpperCase() });
                });
            }

            coinsSet(coins_g);
        })
        .catch((err) => {
            console.log(err);
        });
};

export function AscDesc({ newOld, send_setNewOld }) {
    return (
        <div>
            <input
                type="radio"
                id="newer"
                name="newer"
                value="desc"
                checked={newOld === "desc"}
                onChange={(e) => {
                    send_setNewOld("desc");
                }}
            />
            <label htmlFor="newer" style={{ cursor: "pointer" }}>
                Newer first
            </label>
            &emsp;
            <input
                type="radio"
                id="older"
                name="older"
                value="asc"
                checked={newOld === "asc"}
                onChange={(e) => {
                    send_setNewOld("asc");
                }}
            />
            <label htmlFor="older" style={{ cursor: "pointer" }}>
                Older first
            </label>
        </div>
    );
}

export const ExchangeDiv = ({ opt_exchanges, send_setexchanges }) => {
    return (
        <>
            <span>Exchange:</span>
            <select
                onChange={(e) => {
                    send_setexchanges(e.target.value);
                }} >
                {opt_exchanges.map((option, i) => (
                    <option key={i} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
        </>
    );
};

export const ExchangeDiv_Paskal = ({ opt_exchanges, send_setexchanges }) => {
    return (
        <>
            <span>Exchange:</span>
            <select
                onChange={(e) => {
                    send_setexchanges(e.target.value);
                }} >
                {opt_exchanges.map((option, i) => (
                    <option key={i} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
        </>
    );
};




export const ExchangeDiv_buy = ({ opt_exchanges, send_setexchanges }) => {
    return (
        <>
            <span>Buy Exchange:</span>
            <select
                onChange={(e) => {
                    send_setexchanges(e.target.value);
                }}>
                {opt_exchanges.map((option, i) => (
                    <option key={i} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
        </>
    );
};



export const ExchangeDiv_Trading_Record = ({ exchange_name,opt_exchanges, send_setexchanges }) => {
    return (
        <>
            <span>Exchange:</span>{}
            &nbsp;<select
                onChange={(e) => {
                    send_setexchanges(e.target.value);
                }} value={exchange_name}>
                {opt_exchanges.map((option, i) => (
                    <option key={i} value={option.value} sel>
                        {option.name}
                    </option>
                ))}
            </select>
        </>
    );
};


export const ExchangeDiv_sell = ({ opt_exchanges, send_setexchanges }) => {
    return (
        <>
            <span>Sell Exchange:</span>
            <select
                onChange={(e) => {
                    send_setexchanges(e.target.value);
                }}>
                {opt_exchanges.map((option, i) => (
                    <option key={i} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
        </>
    );
};


export const PositionDiv = ({send_setpositions}) => {
    let opt_positions = []
    opt_positions.push({ value: "Long", name: "Long" });
    opt_positions.push({ value: "Short", name: "Short" });

    const type_div_asset = (
        <>
            <span>Position: </span>
            <select
                onChange={(e) => {
                    send_setpositions(e.target.value);
                }}>
                {opt_positions.map((option, i) => (
                    <option key={i} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
        </>
    );

    return <>{type_div_asset}</>;
};

export const CoinsDiv = ({coins="ALL", opt_coins, send_setcoins, _type = "coin" }) => {
    let _has = 0
    if (opt_coins.length > 0){
        if (_type === "coin" && opt_coins[0].value !== "all") {
            opt_coins.unshift({ value: "all", name: "ALL" })
        }else if (_type === "asset") {
            opt_coins.forEach(e => e.value === "usdt" ? _has = 1 : null)
            if (_has === 0) {
                opt_coins.push({ value: "usdt", name: "USDT" });
                opt_coins.push({ value: "krw", name: "KRW" });
            }
        }else if (_type === "asset_all" && opt_coins[0].value !== "all") {
            opt_coins.forEach(e => e.value === "usdt" ? _has = 1 : null)
            if (_has === 0) {
                opt_coins.unshift({ value: "all", name: "ALL" })
                opt_coins.push({ value: "usdt", name: "USDT" });
                opt_coins.push({ value: "krw", name: "KRW" });
                }
        }
    }
const type_div_1 = (
        <>
            <span>Coin: </span>
            <select
                onChange={(e) => {
                    send_setcoins(e.target.value);
                }}>
                {opt_coins.map((option, i) => (
                    <option key={i} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
        </>
    );

    const type_div_asset = (
        <>
            <span>Asset: </span>
            <select
                onChange={(e) => {
                    send_setcoins(e.target.value);
                }}>
                {opt_coins.map((option, i) => (
                    <option key={i} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
        </>
    );

    return <>{_type === "coin" ? type_div_1 : type_div_asset}</>;
};


export const CoinsDiv2 = ({exchange_name="ALL", opt_coins, send_setcoins, _type = "coin" }) => {
    let _has = 0
    if (opt_coins.length > 0){
        if (_type === "coin" && opt_coins[0].value !== "all") {
            opt_coins.unshift({ value: "all", name: "ALL" })
        }else if (_type === "asset") {
            opt_coins.forEach(e => e.value === "usdt" ? _has = 1 : null)
            if (_has === 0) {
                opt_coins.push({ value: "usdt", name: "USDT" });
                opt_coins.push({ value: "krw", name: "KRW" });
            }
        }else if (_type === "asset_all" && opt_coins[0].value !== "all") {
            opt_coins.forEach(e => e.value === "usdt" ? _has = 1 : null)
            if (_has === 0) {
                opt_coins.unshift({ value: "all", name: "ALL" })
                opt_coins.push({ value: "usdt", name: "USDT" });
                opt_coins.push({ value: "krw", name: "KRW" });
                }
        }
        
        if ( exchange_name.toUpperCase() === "BINANCE" || exchange_name.toUpperCase() === "OKEX"  || exchange_name.toUpperCase() === "MEXC"  || exchange_name.toUpperCase() === "BITGET"  ){
            opt_coins = opt_coins.filter((fruit) => fruit.name !== "KRW")
            //alert(JSON.stringify(opt_coins))

        }else if ( exchange_name.toUpperCase() === "UPBIT" || exchange_name.toUpperCase() === "BITHUMB"  ){
            opt_coins = opt_coins.filter((fruit) => fruit.name !== "USDT")
            //alert(JSON.stringify(opt_coins))
            
        }
        

    }
const type_div_1 = (
        <>
            <span>Coin: </span>
            <select
                onChange={(e) => {
                    send_setcoins(e.target.value);
                }}>
                {opt_coins.map((option, i) => (
                    <option key={i} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
        </>
    );

    const type_div_asset = (
        <>
            <span>Asset: </span>
            <select
                onChange={(e) => {
                    send_setcoins(e.target.value);
                }}>
                {opt_coins.map((option, i) => (
                    <option key={i} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
        </>
    );

    return <>{_type === "coin" ? type_div_1 : type_div_asset}</>;
};

export const GroupDiv = ({groups, opt_groups, send_setgroups }) => {
    return (
        <>
            <span>Group: </span>
            <select
                onChange={(e) => {
                    send_setgroups(e.target.value);
                }} value={groups}>
                {opt_groups.map((option, i) => (
                    <option key={i} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
        </>
    );
};

export const StrategyInputDiv = ({ strategy , send_setstrategy_name }) => {
    return (
        <>
            <span>Strategy: </span>
            <input
                value={strategy}
                type="text"
                placeholder="Typing Strategy Name"
                onChange={(e) => {
                    send_setstrategy_name(e.target.value);
                }}
            />
        </>
    );
};

export const TFDiv = ({  tfs, opt_tfs, send_settfs  }) => {
    return (
        <>
            <span>TF: </span>
            <select
                onChange={(e) => {
                    send_settfs(e.target.value);
                }} value={tfs}>
                {opt_tfs.map((option, i) => (
                    <option key={i} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
        </>
    );
};

export const DateFromToDiv = ({ send_data_from, send_data_to }) => {

    function Date_change(_type, e) {
        if (_type === "from") {
            send_data_from(e)
        }else if (_type === "to") {
            send_data_to(e)
        }
        document.getElementsByName("radio_date").forEach(element => {
            if(element.checked){
                element.checked = false;
                }
        });
    }

    function Radio_change(_type) {
        if (_type === "1") {
            var now = new Date()
            let offset = now.getTimezoneOffset() * 60000; //ms단위라 60000곱해줌
            const today = new Date(now.getTime() - offset);

            //alert(today)
            //alert(today.toISOString().slice(0, 10))
            send_data_from(today.toISOString().slice(0, 10))
        }
        else if (_type === "7") {
            var now = new Date()
            let offset = now.getTimezoneOffset() * 60000; //ms단위라 60000곱해줌
            const today = new Date(now.getTime() - offset);
            const seven = new Date(today.setDate(today.getDate()-7))
            //alert(seven.toISOString().slice(0, 10))
            send_data_from(seven.toISOString().slice(0, 10))
        }
        else if (_type === "30") {
            var now = new Date()
            let offset = now.getTimezoneOffset() * 60000; //ms단위라 60000곱해줌
            const today = new Date(now.getTime() - offset);
            const month = new Date(today.setMonth(today.getMonth()-1))

            //alert(month.toISOString().slice(0, 10))
            send_data_from(month.toISOString().slice(0, 10))
        }
    }
    return (
        <>
            <span>From: </span>
            <input type="date" name="from" style={{ margin: "0 1em 0 0" }} onChange={(e) => Date_change("from", e.target.value+ " 00:00:00")} />
            <span>To: </span>
            <input type="date" name="to" onChange={(e) => Date_change("to", e.target.value + " 23:59:59")} />
            &emsp;&emsp;
            {/* RADIO */}
            &emsp;
            <input type="radio" id="radio_today" name="radio_date" onChange={(e) => Radio_change("1")} />
            &nbsp;
            <label htmlFor="radio_today">Today</label>
            &emsp;
            <input type="radio" id="radio_seven" name="radio_date" onChange={(e) => Radio_change("7")} />
            &nbsp;
            <label htmlFor="radio_seven">7 days</label>
            &emsp;
            <input type="radio" id="radio_month" name="radio_date" onChange={(e) => Radio_change("30")} />
            &nbsp;
            <label htmlFor="radio_month">30 days</label>
        </>
    );
};


export const DateFromToDiv_B = ({ send_data_from, send_data_to }) => {

    const [from_date, setFromDate] = useState("");
    const [to_date, setToDate] = useState("");

    function Date_change(_type, e) {
        if (_type === "from") {
            send_data_from(e + " 00:00:00")
            //alert(e)
            setFromDate(e)
        }else if (_type === "to") {
            send_data_to(e+ " 23:59:59")
            setToDate(e)
        }
        document.getElementsByName("radio_date").forEach(element => {
            if(element.checked){
                element.checked = false;
                }
        });
    }
    function Set_Today() {
        var now = new Date()
        let offset = now.getTimezoneOffset() * 60000; //ms단위라 60000곱해줌
        const today = new Date(now.getTime() - offset);

        //alert(today)
        //alert(today.toISOString().slice(0, 10) + " 00:00:00")
        send_data_from(today.toISOString().slice(0, 10) + " 00:00:00")
        setFromDate(today.toISOString().slice(0, 10))
        setToDate(today.toISOString().slice(0, 10))

        send_data_to("all")
    }

    
    return (
        <>

            <button style={{ margin: "0em 0.5em 0em 1em" }}  className="btn btn-primary btn-sm Dasbord_Button"  onClick={(e) => Set_Today()}>
            Today
            </button>

            <span>From: </span>
            <input type="date" name="from" style={{ margin: "0 1em 0 0" }} onChange={(e) => Date_change("from", e.target.value)} value={from_date} />
            <span>To: </span>
            <input type="date" name="to" onChange={(e) => Date_change("to", e.target.value)} value={to_date} />
        </>
    );
};



export const Time_Div = ({ set_time_form, set_time_to }) => {


    const [from_time, setFromTime] = useState("");
    const [to_time, setToTime] = useState("");

    function Date_change(_type, e) {
        if (_type === "from") {
            //alert(e)
            set_time_form(e)
            setFromTime(e)
        }else if (_type === "to") {
            //alert(e)
            set_time_to(e)
            setToTime(e)
        }
    }
    return (
        <>
            <input type="time" name="from_time" style={{ margin: "0 1em 0 0" }} onChange={(e) => Date_change("from", e.target.value)} value={from_time} />
            <input type="time" name="to_time" onChange={(e) => Date_change("to", e.target.value)} value={to_time} />
        </>
    );
};


export const DateTime_Div = ({ dateFrom,timeFrom ,dateTo,timeTo ,send_data_from, send_data_to ,send_time_from, set_time_to }) => {

    const [from_date, setFromDate] = useState(dateFrom);
    const [from_time, setFromTime] = useState(timeFrom);

    const [to_date, setToDate] = useState(dateTo);
    const [to_time, setToTime] = useState(timeTo);

    function Date_change(_type, e) {
        if (_type === "from_date") {
            //alert(e)
            send_data_from(e)
            setFromDate(e)
        }else if (_type === "to_date") {
            //alert(e)
            send_data_to(e)
            setToDate(e)
        }
        document.getElementsByName("radio_date").forEach(element => {
            if(element.checked){
                element.checked = false;
                }
        });
    }
    function Time_change(_type, e) {
        if (_type === "from_time") {
            //alert(e)
            send_time_from(e)
            setFromTime(e)
        }else if (_type === "to_time") {
            //alert(e)
            set_time_to(e)
            setToTime(e)
        }
    }

    useEffect(() => {
        setFromDate(dateFrom)
        setFromTime(timeFrom)
        setToDate(dateTo)
        setToTime(timeTo)
        console.log("DateTime_Div, useEffect !!! ")
    }, [dateFrom,timeFrom,dateTo,timeTo]);

   
    
    return (
        <>
            <span style={{margin: "0px 10px 0px 0px",  fontSize :"12px",color: "white" }}>From: </span>
            <input type="date" name="from_date" style={{ margin: "0 1em 0 0" }} onChange={(e) => Date_change("from_date", e.target.value)} value={from_date} />
            <input type="time" name="from_time" style={{ margin: "0 1em 0 0" }} onChange={(e) => Time_change("from_time", e.target.value)} value={from_time} />
            <span style={{margin: "0px 10px 0px 0px",  fontSize :"12px",color: "white" }}>To: </span>
            <input type="date" name="to_date"  style={{ margin: "0 1em 0 0" }} onChange={(e) => Date_change("to_date", e.target.value)} value={to_date} />
            <input type="time" name="to_time"  style={{ margin: "0 1em 0 0" }} onChange={(e) => Time_change("to_time", e.target.value)} value={to_time} />

        </>
    );
};





export const DateDiv = ({ setDate }) => {

    const today = new Date()
    const this_date = today.toISOString().slice(0, 10)

    function Date_change(_type, e) {
        setDate(e)
    }

    return (
        <>
            <span>Date: </span>
            <input type="date" defaultValue={this_date} name="input_date" style={{ margin: "0 1em 0 0" }} onChange={(e) => Date_change("input_date", e.target.value)} />
           
        </>
    );
};



/////// variables
/////// variables
export const locale_opt = { maximumFractionDigits: 4 };
export const locale_opt_8 = { maximumFractionDigits: 8 };

export const c_opt_exchanges_all = [
    { value: "all", name: "ALL" },
    { value: "binance", name: "BINANCE" },
    { value: "upbit", name: "UPBIT" },
    { value: "bithumb", name: "BITHUMB" },
    { value: "okex", name: "OKEX" },
    { value: "mexc", name: "MEXC" },
    { value: "bitget", name: "BITGET" },
];

export const c_opt_exchanges = [
    { value: "binance", name: "BINANCE" },
    { value: "upbit", name: "UPBIT" },
    { value: "bithumb", name: "BITHUMB" },
    { value: "okex", name: "OKEX" },
    { value: "mexc", name: "MEXC" },
    { value: "bitget", name: "BITGET" },
];

export const c_opt_strategy_grp_name_all = [
    { value: "all", name: "ALL" },
    { value: "mlc", name: "MLC" },
    { value: "p2v", name: "P2V" },
    { value: "tbt", name: "TBT" },
    { value: "a_c", name: "A_C" },
    { value: "manual", name: "MANUAL" },
];

export const c_opt_strategy_grp_name = [
    { value: "mlc", name: "MLC" },
    { value: "p2v", name: "P2V" },
    { value: "tbt", name: "TBT" },
    { value: "a_c", name: "A_C" },
    { value: "manual", name: "MANUAL" },
];

export const c_opt_strategy_grp_all = [
    { value: "all", name: "ALL" },
    { value: "1", name: "MLC" },
    { value: "2", name: "P2V" },
    { value: "3", name: "TBT" },
    { value: "4", name: "A_C" },
    { value: "5", name: "MANUAL" },
];

export const c_opt_strategy_grp = [
    { value: "1", name: "MLC" },
    { value: "2", name: "P2V" },
    { value: "3", name: "TBT" },
    { value: "4", name: "A_C" },
    { value: "5", name: "MANUAL" },
];

export const c_opt_tfs_all = [
    { value: "all", name: "ALL" },
    { value: "1", name: "1 m" },
    { value: "3", name: "3 m" },
    { value: "5", name: "5 m" },
    { value: "10", name: "10 m" },
    { value: "15", name: "15 m" },
    { value: "30", name: "30 m" },
    { value: "45", name: "45 m" },
    { value: "60", name: "1 h" },
    { value: "120", name: "2 h" },
    { value: "180", name: "3 h" },
    { value: "240", name: "4 h" },
    { value: "360", name: "6 h" },
    { value: "720", name: "12 h" },
    { value: "1440", name: "24 h" },
];

export const c_opt_tfs = [
    { value: "1", name: "1 m" },
    { value: "3", name: "3 m" },
    { value: "5", name: "5 m" },
    { value: "10", name: "10 m" },
    { value: "15", name: "15 m" },
    { value: "30", name: "30 m" },
    { value: "45", name: "45 m" },
    { value: "60", name: "1 h" },
    { value: "120", name: "2 h" },
    { value: "180", name: "3 h" },
    { value: "240", name: "4 h" },
    { value: "360", name: "6 h" },
    { value: "720", name: "12 h" },
    { value: "1440", name: "24 h" },
];

export const c_opt_order_type_all = [
    { value: "all", name: "ALL" },
    { value: "market", name: "MARKET" },
    { value: "limit", name: "LIMIT" },
    { value: "autolimit", name: "AUTO LIMIT" },
    { value: "automarket", name: "AUTO MARKET" },
];

export const c_opt_order_type = [
    { value: "limit", name: "LIMIT" },
    { value: "market", name: "MARKET" },
    { value: "autolimit", name: "AUTO LIMIT" },
    { value: "automarket", name: "AUTO MARKET" },
];

export const c_opt_order_qty_percent = [

    { value: "q", name: "Qty" },
    { value: "p", name: "%" },
];

export const c_opt_pair_is = [
    { value: "yes", name: "YES" },
    { value: "no", name: "NO" },
];
export const c_opt_position = [
    { value: "BUY", name: "Long" },
    { value: "SELL", name: "Short" },
];
export const c_opt_position2 = [
    { value: "", name: "" },
    { value: "CLEAR", name: "Clear" },
    { value: "CLEARALL", name: "ClearAll" },
    { value: "BUY", name: "Long" },
    { value: "SELL", name: "Short" },
    { value: "BUY_SL", name: "Long_sl" },
    { value: "SELL_SL", name: "Short_sl" },
];
