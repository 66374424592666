import React from 'react';
import "./ProgressBar.css";
import CircularProgressVariants from "./CircularProgressVariants";

const Progress = ( props ) => {
    // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
    const { open, close} = props;

    return (
        // 모달이 열릴때 openModal 클래스가 생성된다.
        <>
        <div  className={ open ? 'openModal modal' : 'modal' } style={{ color: "black" }}>
            { open ? (  
                <>
                <CircularProgressVariants />
                </>
            ) : null }
        </div>
        </>
    )
}

export default Progress