import React, { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";

import "./botStatusCurrentBalance.css";
import { locale_opt } from "../../../common/Commons";

function BotStatus({ exchangesSelect, botCnt, balanceStatus }) {

    const [traget_price, setTargetPrice] = useState("");
    const [update_task_type, setupdate_task_type] = useState("save");

    
    
    const forceControl = (title, msg, exchanges_str, key) => {
        if (window.confirm(title)) {
            let time = dayjs().format("YYYY-MM-DD hh:mm:ss");

            let splits = key[0].split("_");
            const strategy_group = splits[0];
            const coin_type = splits[1];
            const time_frame = splits[splits.length - 1];

            splits.splice(0, 2);
            let strategy_name = splits.toString().replaceAll(",", " ");

            const this_data = {
                    getmodel : "botScheduleCtl_model",
                    gettogo : "trading_view",

                    params : {
                        strategy_group: strategy_group === "MLC" ? "1" : strategy_group === "P2V" ? "2" : "3",
                        strategy_name: strategy_name,
                        exchange_name: exchanges_str + ":" + key[3] + key[5],
                        coin_type: coin_type + key[5],
                        time_frame: time_frame,
                        position: "BUY",
                        price: "0",
                        operation_type: msg,
                        alarm_sent_time: String(new Date(time) / 1000)
                    }
            };
            alert(JSON.stringify(this_data))
            
            axios
                .post(process.env.REACT_APP_TV_SERVER, this_data)
                .then((res) => {
                    window.location.reload();
                })
                .catch((err) => console.log(err));
            
        }
    };

    function CPrice({grp, ticker}){
        let result = ""
        balanceStatus.map((k, i) => {
            if (k.grp === grp && k.crypto === ticker) {
                result = parseFloat(k.c_price).toLocaleString("ko-KR", locale_opt)
            } 
        }) 
        return result
    }

    function edit_trading(){
        setupdate_task_type("edit");
    }

    function save_trading(title, msg, exchanges_str, key){
        if (window.confirm(title)) {
            let time = dayjs().format("YYYY-MM-DD hh:mm:ss");

            let splits = key[0].split("_");
            const strategy_group = splits[0];
            const coin_type = splits[1];
            const time_frame = splits[splits.length - 1];

            splits.splice(0, 2);
            let strategy_name = splits.toString().replaceAll(",", " ");

            const this_data = {
                    getmodel : "botScheduleCtl_model",
                    gettogo : "trading_view",

                    params : {
                        strategy_group: strategy_group === "MLC" ? "1" : strategy_group === "P2V" ? "2" : "3",
                        strategy_name: strategy_name,
                        exchange_name: exchanges_str + ":" + key[3] + key[5],
                        coin_type: coin_type + key[5],
                        time_frame: time_frame,
                        position: "BUY",
                        price: "0",
                        operation_type: msg,
                        alarm_sent_time: String(new Date(time) / 1000),
                        traget_price : traget_price,
                    }
            };
            alert(JSON.stringify(this_data))
            
            axios
                .post(process.env.REACT_APP_TV_SERVER, this_data)
                .then((res) => {
                    window.location.reload();
                })
                .catch((err) => console.log(err));
            
        }
    }

    

    return (
        <div className="first_div">
            <div className="second_div">
                <div>
                    <h5>Trading Strategy Status</h5>
                </div>
                &emsp;
                <table className="">
                    <thead style={{ background: "BLACK" }}>
                        <tr>
                            <th style={{ width: "3%" }}>TICKER</th>
                            <th style={{ width: "4%" }}>STRATEGY</th>
                            <th style={{ width: "2%" }}>COUNT</th>
                            <th>BOT 1</th>
                            <th>BOT 2</th>
                            <th>BOT 3</th>
                            <th>BOT 4</th>
                            <th>BOT 5</th>
                            {/* <th>BOT 6</th>
                            <th>BOT 7</th>
                            <th>BOT 8</th>
                            <th>BOT 9</th>
                            <th>BOT 10</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {botCnt.map((name, i) =>
                            name.exchanges === exchangesSelect ? (
                                name["bot_1"] ? (
                                    <tr key={i} style={{ background: `${name.strategy_str === "MLC" ? "#0070C0" : name.strategy_str === "P2V" ? "#1E8062" : "#7030A0"}` }}>
                                        <td>{name.coin_ticker}</td>
                                        <td>{name.strategy_str}</td>
                                        <td>{name.strategy_count}</td>
                                        {/* {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((name_2, i_2) => ( */}
                                        {[1, 2, 3, 4, 5].map((name_2, i_2) => (
                                            <td key={i_2} style={{ width: "250px" }}>
                                                {name["bot_" + name_2] ? (
                                                    <div
                                                        style={{
                                                            display: "grid",
                                                            textAlign: "center",
                                                        }}>
                                                        <span>{name["bot_" + name_2][0]}</span>
                                                        <span>({name["bot_" + name_2][1]})</span>
                                                        <span>
                                                            {" "}
                                                            {name["bot_" + name_2][6] + "%"} ({name["bot_" + name_2][2].toLocaleString("ko-KR", locale_opt)} {name["bot_" + name_2][3]})
                                                        </span>
                                                        <span>
                                                            {" "}
                                                            SELL PRICE : {name["bot_" + name_2][7].toLocaleString("ko-KR", locale_opt)} {name["bot_" + name_2][5]}
                                                        </span>
                                                        <span>
                                                            {" "}
                                                            AMOUNT : {(name["bot_" + name_2][7] * name["bot_" + name_2][2]).toLocaleString("ko-KR", locale_opt)} {name["bot_" + name_2][5]}
                                                        </span>
                                                        <span style={{ color: "#FFC000" }}>
                                                            {" "}
                                                            Current Price : <CPrice grp={name.strategy_str} ticker={name.coin_ticker} /> {name["bot_" + name_2][5]}
                                                        </span>

                                                        
                                                        <div style={{ display: "flex" }}>
                                                            {/* target price */}
                                                            <div style={{justifyContent : "center" ,width : "80%" }}>
                                                            Target Price :  <input id='traget_price' disabled={update_task_type === "edit" ? "disabled" : ""} type="text" style={{ margin: "0px 0px 0px 0px" , width: "50%" }} onChange={(e) => setTargetPrice(e.target.value)}/>
                                                            </div>
                                                            {/* edit button */}
                                                            <div hidden={update_task_type == "edit" ? true : false}  style={{margin: "2.5em 0px 0px 2.5em"  , display : "flex" , width : "50%" }}>
                                                                <div style={{ width : "100%" }}>
                                                                    <button className="btn btn-info btn-sm" 
                                                                    onClick={() => edit_trading()} >Edit</button>
                                                                </div>
                                                            </div>
                                                            {/* save button */}
                                                            <div hidden={update_task_type == "save" ? false : true}  style={{margin: "2.5em 0px 1px 2.5em"  , display : "flex" , width : "50%" }}>
                                                                <div style={{ width : "100%" }}>
                                                                    <button className="btn btn-info btn-sm" 
                                                                    onClick={() => save_trading("Do you want to update target price?", "update_target_price", name.exchanges_str, name["bot_" + name_2]) } >Save</button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        

                                                        <div style={{ display: "flex" }}>
                                                            <button
                                                                style={{ background: "green", color: "white", width: "110px", margin: "auto" }}
                                                                onClick={() => forceControl("Close position?", "semi", name.exchanges_str, name["bot_" + name_2])}>
                                                                Close Position
                                                            </button>
                                                            <button
                                                                style={{ background: "red", color: "white", width: "110px", margin: "auto" }}
                                                                onClick={() => forceControl("Terminate position?", "terminate", name.exchanges_str, name["bot_" + name_2])}>
                                                                Terminate
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    "X"
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                ) : null
                            ) : null
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default BotStatus;